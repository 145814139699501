// api路径 '/prod-api'
const baseUrl = process.env.VUE_APP_API

// 汇总接口
const querySql = baseUrl + '/thoughtpower-scm-sqlcomputesvc/api/service/querySql'
// 明细分页接口
const querySqlByPage = baseUrl + '/thoughtpower-scm-sqlcomputesvc/api/service/querySqlByPage'

// 导出接口
const queryExport = baseUrl + '/thoughtpower-scm-sqlcomputesvc/api/service/queryAndExport'

// 轮播图片接口1
const homepageImage = baseUrl + '/thoughtpower-scm-homepageimagesvc/api/object/homepageImage/lookup'
// 轮播图片接口2
const attachment = baseUrl + '/thoughtpower-scm-attachmentsvc/api/object/attachment/lookup'

export default {
  querySql,
  querySqlByPage,
  queryExport,
  homepageImage,
  attachment
}
