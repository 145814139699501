// import { createStore } from 'vuex'
import Storage from '@/utils/storage'

// 登录用户状态管理
// 获取属性 this.$store.state.userState.userInfo
// 执行方法 this.$store.dispatch('getUser')
const userState = ({
  // 数据
  state: {
    userInfo: {
      uname: '', // 用户名(登录)
      nname: '',  // 昵称
      utype: '', // 用户类型
      remark: '',
      token: ''    
    }
  },
  // 方法
  mutations: {
    getUser (state) {
      const ui = Storage.session.get('kmust-webui')
      // console.log('land-webui')
      if (ui) {
        state.userInfo = ui
      } else {
        state.userInfo = null
      }
    },
    setUser (state, obj) {
      if(obj) {
        state.userInfo = obj
        Storage.session.set('kmust-webui', obj)
      } else { // 登出
        state.userInfo = obj
        Storage.session.remove('kmust-webui')
      }
    }
  },
  // 执行mutations中的方法，异步操作actions
  actions: {
    getUser(context) {      
      context.commit("getUser")
    },
    setUser(context, obj) {      
      context.commit("setUser", obj)
    }
  },
  getters: { //与计算属性相似
    // num (state) {
    //    return state.count+1
    // }
  }
})
export default userState
