import { createStore } from 'vuex'
import tokenState from './tokenState'
import userState from './userState'

// 模块化状态管理
const store = createStore({
  modules: {
    tokenState: tokenState,
    userState: userState
  }
})
export default store
