// import { createStore } from 'vuex'
import Storage from '@/utils/storage'

// 登录用户状态管理
// 获取属性 this.$store.state.tokenState.token
// 执行方法 this.$store.dispatch('getToken')
const tokenState = ({
  // 数据
  state: {
    token: ''
  },
  // 方法
  mutations: {
    getToken (state) {
      const tok = Storage.session.get('kmust-webtoken')      
      if (tok) {
        state.token = tok
      } else {
        state.token = null
      }
    },
    setToken (state, obj) {
      state.token = obj
      if(obj) {
        Storage.session.set('kmust-webtoken', obj)
      } else { // 登出
        Storage.session.remove('kmust-webtoken')
      }
    }
  },
  // 执行mutations中的方法，异步操作actions
  actions: {
    getToken (context) {      
      context.commit("getToken")
    },
    setToken (context, obj) {      
      context.commit("setToken", obj)
    }
  },
  getters: { //与计算属性相似
    // num (state) {
    //    return state.count+1
    // }
  }
})
export default tokenState
