//VUE_APP_URL = 'https://zbrjtest.kust.edu.cn'
import { createApp } from 'vue'
import App from '@/App.vue'
import router from '@/router'
import store from '@/store'
import * as echarts from 'echarts'
import ElementPlus from 'element-plus'
import zhCn from 'element-plus/es/locale/lang/zh-cn'
import 'element-plus/dist/index.css'
// 导入所有图标并进行全局注册
import * as ElementPlusIconsVue from '@element-plus/icons-vue'
// 引入全局的样式文件
import '@/assets/css/global.css'
import http from '@/utils/http'
import Api from '@/utils/api'
import Storage from '@/utils/storage'
import Cookie from '@/utils/cookie.js'
import Fun from '@/utils/method_utils'
import fileDownload from 'js-file-download'

const app = createApp(App)
app.use(router)
app.use(store)
app.use(ElementPlus, {
  locale: zhCn
})

router.beforeEach((to, from, next) => {
  if (to.matched.length === 0) {
    next('/404')
  }
  // console.log('to1=', to)
  // console.log('from1=', to)
  // // 要求登录的页面
  // if (to.meta.requireLogin) {
  //   // 获取用户状态信息
  //   let user = store.state.userState.userInfo
  //   if (!user) {
  //     next()
  //     router.push({ path: '/login', query: { redirect: to.fullPath }})      
  //   }
  // }  
  // 路由发生变化修改页面title
  if (to.meta.title) {
    document.title = to.meta.title
  }  
  next()
})

// 导入所有图标并进行全局注册
for (const [key, component] of Object.entries(ElementPlusIconsVue)) {
  app.component(key, component)
}
// 挂载全局方法 将axios挂载到Vue的原型对象上
app.config.globalProperties.$http = http
app.config.globalProperties.$api = Api
app.config.globalProperties.$echarts = echarts
app.config.globalProperties.$storage = Storage
app.config.globalProperties.$cookie = Cookie
app.config.globalProperties.$fun = Fun
app.config.globalProperties.$fileDownload = fileDownload
app.mount('#app')
