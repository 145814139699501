/**
 * @description 保存cookie
 * @param {Object} json 需要存储cookie的对象
 * @param {Number} days 默认存储多少小时
 */
 function setCookie(json, days) {
  // 设置过期时间
  let data = new Date(
      // new Date().getTime() + days * 24 * 60 * 60 * 1000   // 天
      new Date().getTime() + days * 60 * 60 * 1000   // 小时
  ).toUTCString();  
  for (var key in json) {
    document.cookie = key + "=" + json[key] + "; path=/; expires=" + data;
  }
}

/** 
* @description 获取cookie
* @param {String} name 需要获取cookie的key
*/
function getCookie(name) {
  var arr = document.cookie.match(new RegExp("(^| )" + name + "=([^;]*)(;|$)"));
  if (arr != null) {
      // return unescape(arr[2])
      return decodeURIComponent(arr[2])
  } else {
      return null
  }
}

/** 
* @description 删除cookie
* @param {String} name 需要删除cookie的key
*/
function clearCookie(name) {
  let json = {};
  json[name] = '';
  setCookie(json, -1)
}

// function clearCookie(key) {  
//   // setCookie(json, -1)
//   let data = new Date(
//     new Date().getTime() + (-1) * 24 * 60 * 60 * 1000
//   ).toUTCString();
//   document.cookie = key + "=0; path=/; expires=" + data
// }

export default {
  setCookie,
  getCookie,
  clearCookie  
}
