<template>
  <!-- 路径（包括参数）不同就会重新刷新 -->
  <router-view :key='$route.fullPath' />
</template>

<script>
export default {
  data () {
    return {
    }
  },
  create(){
  },
  //模板编译完成
  mounted(){
    // 获取最新的用户信息
    // this.$store.dispatch('getUser')
  }
}
</script>

<style lang="less">
</style>
