// 封装一个storage方法
// 本地缓存
const local = {
  set (key, value) {
    localStorage.setItem(key, JSON.stringify(value))
  },
  get (key) {
    return JSON.parse(localStorage.getItem(key))
  },
  remove (key) {
    localStorage.removeItem(key)
  },
  clear () {
    localStorage.clear()
  }
}
// 临时缓存 浏览器窗口关闭而消失
const session = {
  set (key, value) {
    sessionStorage.setItem(key, JSON.stringify(value))
  },
  get (key) {
    return JSON.parse(sessionStorage.getItem(key))
  },
  remove (key) {
    sessionStorage.removeItem(key)
  },
  clear () {
    sessionStorage.clear()
  }  
}
// export default storage
export default {
  local,
  session
}
