import { createRouter, createWebHashHistory } from 'vue-router'

const tit = '五里多农博广场--'
const routes = [
  {
    path: '/',
    redirect: '/index'    
  }, 
  {
    path: '/index',
    meta: {
      title: tit + '个人中心'
      // requireLogin: true
    },
    component: () => import('@/views/layout'),
    children: [
      {
        path: '/index',
        meta: { 
          // keepAlive: true,
          title: tit + '首页'
        },
        component: () => import('@/views/index')
      },
      {
        path: '/about',
        meta: { 
          // keepAlive: true,
          title: tit + '关于我们'
        },
        component: () => import('@/views/about')
      },
      {
        path: '/dealdetail',
        meta: { 
          // keepAlive: true,
          title: tit + '收支交易明细'
        },
        component: () => import('@/views/dealdetail')
      },
      {
        path: '/purchasesummary',
        meta: { 
          // keepAlive: true,
          title: tit + '商户采购汇总'
        },
        component: () => import('@/views/purchasesummary')
      },  
      {
        path: '/purchasedetail',
        meta: { 
          // keepAlive: true,
          title: tit + '商户采购明细'
        },
        component: () => import('@/views/purchasedetail')
      },
      {
        path: '/sellsummary',
        meta: {
          title: tit + '商户销售汇总'
        },
        component: () => import('@/views/sellsummary')
      },  
      {
        path: '/selldetail',
        meta: {
          title: tit + '商户销售明细'
        },
        component: () => import('@/views/selldetail')
      },
      {
        path: '/paysummary',
        meta: {
          title: tit + '商户收款汇总'
        },
        component: () => import('@/views/paysummary')
      },
      {
        path: '/paydetail',
        meta: {
          title: tit + '商户收款明细'
        },
        component: () => import('@/views/paydetail')
      },  
      {
        path: '/daysummary',
        meta: {
          title: tit + '交易日报汇总'
        },
        component: () => import('@/views/daysummary')
      },
      {
        path: '/daydetail',
        meta: {
          title: tit + '交易日报明细'
        },
        component: () => import('@/views/daydetail')
      },
      {
        path: '/monthsummary',
        meta: {
          title: tit + '交易月报汇总'
        },
        component: () => import('@/views/monthsummary')
      },
      {
        path: '/monthdetail',
        meta: {
          title: tit + '交易月报明细'
        },
        component: () => import('@/views/monthdetail')
      }    
    ]
  },
  {
    path: '/404',
    meta: {
      title: tit + '404页面'
    },
    component: () => import('@/views/404')
  }   
]
const router = createRouter({
  // 去掉#号
  // history: createWebHistory(process.env.VUE_APP_PATH),
  history: createWebHashHistory(),
  // 记录页面滚动位置
  // scrollBehavior (to, from, savedPosition) {
  //   if (savedPosition) { // 如果savedPosition存在，滚动条会自动跳到记录的值的地方
  //     return savedPosition
  //   } else {
  //     return { x: 0, y: 0 } // savedPosition也是一个记录x轴和y轴位置的对象
  //   }
  // },
  routes
})
export default router