// 定义全局方法
// 判断是否有当前功能权限
function isQx (sysqx, tag) {
  if (sysqx === undefined || sysqx === '') {
    return false
  } else
  if (sysqx.indexOf(tag) > -1) {
    return true
  } else {
    return false
  }
}
// 判断数组arr中是否有当前项tag,如是否显示表列
function isExist (arr, tag) {
  if (arr === undefined || arr === null) {
    return false
  } else
  if (arr.indexOf(tag) > -1) {
    return true
  } else {
    return false
  }
}

//判断字符是否为空的方法
function isEmpty (res) {
  // console.log('res=', res)
  if(typeof res == 'undefined' || res == null || res.trim() == '') {
    return true
  } else {
    return false
  }
}

export default {  
  isQx,
  isExist,
  isEmpty
}
